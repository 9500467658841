








































import { Component, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { Loader } from '../../shared/molecules/Loader'
import { RouteName } from '../routes'
import { Inject, logger } from '../../../support'
import { IOrdersRepository, OrdersRepositoryType } from '../../orders/contracts/repository'
import { ORDER_KEY } from '../services/cart'
import { OrderData } from '../../../contexts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
enum PaymentStatus {
  Failed,
  Pending,
  Succeed
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<PaymentReturnView>({
  name: 'PaymentReturnView',
  components: { Loader },

  mounted (): void {
    this.loadOrder()
    this.considerQuery()

    if (typeof this.status === 'undefined') {
      this.considerStatus()
    }
  }
})
export class PaymentReturnView extends Vue {
  @Inject(OrdersRepositoryType)
  protected readonly ordersRepository!: IOrdersRepository

  public ableToRedirect: boolean = true
  protected status: PaymentStatus | null = null
  public errorMessage: string = ''
  public order: OrderData | null = null

  protected get code (): string {
    return String(this.$route.query.error ?? this.$route.query.paymentStatus ?? '')
  }

  public get backLink (): RawLocation {
    return '/'
  }

  public get icon (): string {
    return require('@/assets/images/payment-pending.svg')
  }

  public considerRedirection (): void {
    switch (this.status) {
      case PaymentStatus.Failed:
        setTimeout(() => this.$router.push({
          name: `checkout.${RouteName.PaymentError}`,
          params: { code: this.code }
        }), 2000)
        break
      case PaymentStatus.Pending:
        setTimeout(() => {
          this.ableToRedirect = false
        }, 3500)
        break
      case PaymentStatus.Succeed:
        setTimeout(() => this.$router.push({ name: `checkout.${RouteName.ThankYou}` }), 2000)
    }
  }

  public loadOrder (): void {
    try {
      const orderData = localStorage.getItem(ORDER_KEY)

      if (!orderData) {
        throw new Error('Missing order data in localStorage')
      }

      this.order = JSON.parse(orderData)
    } catch (e) {
      logger(e)
    }
  }

  public get orderNumber (): string {
    if (!this.order || !this.order.orderNumber) {
      return ''
    }

    return this.order.orderNumber
  }

  protected considerQuery (): void {
    if (typeof this.$route.query.paymentStatus !== 'undefined') {
      switch (String(this.$route.query.paymentStatus).toLowerCase()) {
        case 'confirmed':
          this.status = PaymentStatus.Succeed
          break
        case 'rejected':
          this.status = PaymentStatus.Failed
          break
        default:
          this.status = PaymentStatus.Pending
      }
    } else if (typeof this.$route.query.error !== 'undefined') {
      this.status = PaymentStatus.Failed
    }

    this.considerRedirection()
  }

  protected async considerStatus (): Promise<void> {
    try {
      const orderPaymentStatus = await this.ordersRepository.checkOrderPaymentStatus(this.orderNumber)
      switch (orderPaymentStatus) {
        case 'complete':
          this.status = PaymentStatus.Succeed
          break
        case 'failed':
          this.status = PaymentStatus.Failed
          break
        default:
          this.status = PaymentStatus.Pending
      }

      this.considerRedirection()
    } catch (error) {
      this.errorMessage = (error as Error).message
    } finally {
    }
  }
}

export default PaymentReturnView
