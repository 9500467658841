
















































import { Component, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { OrderData } from '../../../contexts'
import { logger } from '../../../support'

import { ORDER_KEY } from '../services/cart'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<PaymentErrorView>({
  name: 'PaymentErrorView',
  components: {},
  mounted () {
    this.loadOrder()
  }
})
export class PaymentErrorView extends Vue {
  public order: OrderData | null = null

  public get backLink (): RawLocation {
    return '/'
  }

  public get icon (): string {
    return require('@/assets/images/payment-error.svg')
  }

  public loadOrder (): void {
    try {
      const orderData = localStorage.getItem(ORDER_KEY)

      if (!orderData) {
        throw new Error('Missing order data in localStorage')
      }

      this.order = JSON.parse(orderData)
    } catch (e) {
      logger(e)
    }
  }
}

export default PaymentErrorView
