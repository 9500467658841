






































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { CartAnalyticsMixin } from '../../shared/mixins/cart-analytics.mixin'
import { CartTax, OrderData, PaymentMethodCode } from '../../../contexts'
import { DrawerType, IDrawer } from '../../shared/contracts/services'
import { Inject, logger } from '../../../support'
import { RouteNames } from '../../auth/routes'
import { StructureConfigurable } from '../../../support/mixins'

import { AuthMixin, openAuthDrawer } from '../../auth/shared'
import { AuthDrawerTab } from '../../auth/organisms/AuthDrawer/AuthDrawer.config'

import { ORDER_KEY } from '../services/cart'
import { THANK_YOU_COMPONENT_KEY, THANK_YOU_DEFAULT_CONFIG } from './views.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ThankYouView>({
  name: 'ThankYouView',
  created (): void {
    this.config = this.getComponentConfig(THANK_YOU_COMPONENT_KEY, { ...THANK_YOU_DEFAULT_CONFIG })
  },
  mounted (): void {
    this.isLoading = this.isWaitingForAuth
    this.loadOrder()
    this.purchaseEvent()

    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('ceneo_accepted') === 'true' && this.lang === 'pl') {
        this.callZO()
      }
    }
  }
})
export class ThankYouView extends Mixins(AuthMixin, CartAnalyticsMixin, StructureConfigurable) {
  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public isLoading: boolean = true
  public PaymentMethodCode = PaymentMethodCode
  public order: OrderData | null = null

  public get backLink (): RawLocation {
    return '/'
  }

  public get lang () {
    return this.$i18n.locale
  }

  public get buttonIcon (): string | null {
    return this.getConfigProperty('buttonIcon')
  }

  public get createAccountStrategy (): string {
    return this.getConfigProperty<string>('createAccountStrategy')
  }

  public get icon (): string {
    return require('@/assets/images/payment-success.svg')
  }

  public get instructions (): string[][] {
    if (!this.order?.instructions) {
      return []
    }

    const input = this.order?.instructions.split('\r\n').filter(line => !!line)
    const instructions = []

    for (let i = 0; i < input.length; i += 2) {
      instructions.push([input[i], input[i + 1]])
    }

    return instructions
  }

  public get loggedInText (): string {
    const text = this.$t('front.checkout.views.ThankYouView.loggedIn.text').toString()

    return (text === 'front.checkout.views.ThankYouView.loggedIn.text') ? '' : text
  }

  public get orderNumber (): string {
    if (!this.order) {
      return ''
    }

    return `Zam. nr #${this.order.orderNumber}`
  }

  public get registerLink (): RawLocation {
    return { name: 'profile.me' }
  }

  public get thankYouText () {
    return {
      orderNumber: this.order?.orderNumber,
      from: this.order?.deliveryFrom,
      to: this.order?.deliveryTo
    }
  }

  public copy (name: string, index?: number): void {
    const ref = this.$refs[name] as HTMLInputElement | HTMLInputElement[]
    let toCopy: HTMLInputElement

    if (!ref) {
      return
    }

    if (index !== undefined) {
      toCopy = (ref as HTMLInputElement[])[index]
    } else {
      toCopy = ref as HTMLInputElement
    }

    toCopy.focus()
    document.execCommand('copy')
  }

  public loadOrder (): void {
    try {
      const orderData = localStorage.getItem(ORDER_KEY)

      if (!orderData) {
        throw new Error('Missing order data in localStorage')
      }

      this.order = JSON.parse(orderData)
    } catch (e) {
      logger(e)
    }
  }

  public register (): void {
    if (this.createAccountStrategy === 'router') {
      this.$router.push({ name: `auth.${RouteNames.Auth}` })
      return
    }

    if (this.createAccountStrategy === 'drawer' && this.drawerConnector) {
      openAuthDrawer(this.drawerConnector, { activeTab: AuthDrawerTab.Signup })
    }
  }

  @Watch('isWaitingForAuth')
  public setLoading () {
    this.isLoading = false
  }

  protected purchaseEvent (): void {
    let tax: number | undefined
    if (this.order && this.order?.cart.hasOwnProperty('taxes')) {
      tax = this.order.cart.taxes.reduce((acc: number, tax: CartTax) => {
        return acc + tax.amount.value
      }, 0)
    }

    // todo: lirene: app:checkout.purchase
    this.eventBus.emit('app:checkout.purchaseTyp', {
      ...this.getPurchasePayload(this.order?.cart ?? null, tax),
      transactionId: this.order ? this.order.orderNumber : ''
    })
  }

  private callZO () {
    if (!this.order) return

    const payload = {
      client_email: this.order.cart.email,
      order_id: this.order.orderNumber,
      work_days_to_send_questionnaire: 7,
      amount: this.order.cart.total.value,
      shop_products: this.order.cart.items.map(item => {
        return {
          id: item.product.sku,
          price: item.prices.price.value,
          quantity: item.quantity,
          currency: item.prices.price.currency
        }
      })
    }

    // window.dataLayer.push({
    //   ceneo: payload
    // })

    if ('_ceneo' in window && typeof window._ceneo === 'function') {
      window._ceneo('transaction', payload)
    }
  }
}

export default ThankYouView
